<template>
  <div>
    <v-container>
      <v-row>
        <v-col cols="6">
          <PageTitleComponent title="지역별 간병인 통계" />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="4">
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">
                    지역
                  </th>
                  <th class="text-left">
                    간병인 수
                  </th>
                </tr>
              </thead>

              <tbody>
                <tr v-for="item in list" :key="item.workingAreaIdx">
                  <td>{{ transformLocationName(item.workingAreaIdx) }}</td>
                  <td>{{ item.count }}</td>
                </tr>
                <tr>
                  <td>총합</td>
                  <td>{{ list.map(v => v.count).reduce((prev, curr) => prev + curr) }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import PageTitleComponent from '../_elements/title/PageTitle'
import Config from '@/config'
import axios from 'axios'

const locations = [
  { text: '전체', value: 1 },
  { text: '서울', value: 2 },
  { text: '부산', value: 3 },
  { text: '대구', value: 4 },
  { text: '인천', value: 5 },
  { text: '광주', value: 6 },
  { text: '대전', value: 7 },
  { text: '울산', value: 8 },
  { text: '세종', value: 9 },
  { text: '경기', value: 10 },
  { text: '강원', value: 11 },
  { text: '충북', value: 12 },
  { text: '충남', value: 13 },
  { text: '전북', value: 14 },
  { text: '전남', value: 15 },
  { text: '경북', value: 16 },
  { text: '경남', value: 17 },
  { text: '제주', value: 18 }
]

export default {
  components: {
    PageTitleComponent
  },
  data() {
    return {
      list: [],
      locations
    }
  },
  mounted() {
    axios.get(`${Config.Api.Service2.url}/caregiver/stats/area`).then(({ data }) => (this.list = data))
  },
  methods: {
    transformLocationName(id) {
      return this.locations.find(location => location.value === id).text
    }
  }
}
</script>
